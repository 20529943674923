.checkbox-table {
  border-collapse: collapse;
  width: 100%;
}

.checkbox-table th,
.checkbox-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  width: 100px;
}

.checkbox-table .highlighted {
  background-color: rgba(
    51,
    153,
    255,
    0.2
  ); /* Highlight color for selected rows/columns */
}
