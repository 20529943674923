.new-transaction-form {
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 65px);
}

.new-transaction-form form {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  width: 800px;
  padding: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 20px;
  width: 100%;
  gap: 10px;
}

.form-group {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  font-size: 12px;
  color: #444444;
  margin: 0 0 16px 0;
}

.form-group input,
.form-group select {
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  height: 30px;
  outline: 0;
  width: 100%;
  font-size: 12px;
}

.form-row.buttons {
  display: flex;
  justify-content: flex-start;
}

.form-row.buttons .btn {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.form-row.buttons .btn-primary {
  background-color: #002d5c;
  color: white;
}

.form-row.buttons .btn-secondary {
  background-color: #ffc107;
  color: white;
}

.form-row.buttons .btn:hover {
  opacity: 0.9;
}
.transaction-amount {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.transaction-amount .form-group input {
  width: 70% !important;
}
.transaction-amount .form-group select {
  width: 20% !important;
}
input[type="file"]::file-selector-button {
  background-color: #002d5c;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.new-transaction-form h4 {
  text-align: left;
  margin-bottom: 20px;
  color: #808080;
  margin-bottom: 30px;
}
