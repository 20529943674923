.work-queue {
  height: 100vh;
  width: 100vw;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  width: calc(100% - 32px);
}
.top-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 135px 20px 0px 20px;
}

.table-div {
  padding: 16px 20px 20px 20px;
}

button {
  cursor: pointer;
  background: #003a75;
  color: white;
  margin-left: 20px;
  font-size: 12px;
  font-family: arial;
  min-height: 30px;
  height: 30px;
  line-height: 1;
  border-radius: 4px;
  padding: 0 16px;
  outline: 0;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.button:hover {
  background-color: #0056b3;
}

.fa-download::before {
  content: "\f019";
}

.fa-lock:before {
  content: "\f023";
  color: #808080;
  font-size: 18px;
}
.fa-pencil:before {
  color: #003a75;
  font-size: 18px;
}
.fa-archive:before {
  color: #003a75;
  font-size: 18px;
  opacity: 0.4;
}
.fa-trash:before {
  color: #003a75;
  font-size: 18px;
  opacity: 0.4;
}
.fa-plus-circle:before {
  color: #003a75;
  font-size: 18px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  font-size: 12px;
  height: 35px;
  text-align: left;
  padding: 0 8px;
}

.table th {
  text-align: left;
  background: #003a75;
  color: #ffffff;
  border: none;
}
.work-queue-thead {
  background: #003a75;
  width: 100%;
}
.pagination {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
}

.radiobutton:checked {
  background: #003a75;
}

.filter-container {
  width: calc(100% - 32px);
  float: left;
  background: white;
  padding: 16px;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 2px 15px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
}
.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  width: 80%;
}
.filter-action-buttons {
  width: 20%;
  align-self: flex-end;
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 20px;
  width: calc((100% / 8) - 16px);
}

.filter-label {
  font-size: 12px;
  color: #444444;
  margin: 0 0 16px 0;
}
.input-field input {
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  height: 30px;
  outline: 0;
  width: 100%;
  font-size: 12px;
}

input[type="date" i] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}
.input-field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  height: 30px;
  outline: 0;
  width: 100%;
  font-size: 12px;
}

.dropdown-input {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #d3d3d3;
}
