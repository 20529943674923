.menu {
  position: absolute;
  top: 0;
  width: 100%;
  background: white;
  height: 65px;
  min-width: 1200px;
  box-shadow: 0 9px 8px -7px rgba(0, 0, 0, 0.4) !important;
}
.app-logo {
  float: left;
  height: 41px;
  padding: 12px;
}
.user-menu {
  font-family: arial;
  display: block;
  float: right;
  width: 150px;
  height: 65px;
  border-left: 1px solid #808080;
  padding: 8px 12px;
  box-sizing: border-box;
  cursor: pointer;
}
.user-image {
  float: right;
  width: 45px;
  padding: 0;
}

.user-menu > p {
  font-weight: bold;
  line-height: 25px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nav {
  display: block;
  height: 65px;
  color: #003a75;
  box-sizing: border-box;
}
.nav > a {
  position: relative;
  float: left;
  font-size: 14px;
  line-height: 65px;
  text-decoration: none;
  margin: 0 20px;
  color: #003a75;
  cursor: pointer;
}
