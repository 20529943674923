.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 1000; /* adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  width: 95%;
  height: 90%;
  overflow-y: scroll;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.close-button:hover {
  color: #000;
}
