.document-viewer {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: calc(100vh - 180px);
  overflow-y: hidden;
  scrollbar-width: none;
  margin: 135px 20px 20px 20px;
}

.document-viewer-body {
  overflow: hidden;
}

.document-header {
  text-align: left;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 20px;
}

.document-header input[type="range" i] {
  appearance: auto;
  cursor: default;
  color: light-dark(rgb(16, 16, 16), rgb(255, 255, 255));
  padding: initial;
  border: initial;
  margin: 2px;
  accent-color: #003a75;
}

.document-header h2 {
  line-height: 1;
  padding: 10px;
  margin: 0;
  color: #59aa5b;

  font-size: 20px;
  font-weight: bold;
}
.document-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 100%;
  padding-bottom: 20px;
  overflow: hidden;
}
.document-content {
  width: 43%;
  border-right: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.document-page {
  padding: 20px;
  min-height: 400px;
  overflow: scroll;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.document-sidebar {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  overflow-y: scroll;
  padding-bottom: 20px;
  height: 100%;
}

.thumbnail-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  width: 100%;
  overflow: scroll;
}

.thumbnail {
  position: relative;
  display: inline-block;
  width: 100%;
  /* border: 1px solid #ddd; */
  margin: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s;
}

.active {
  background-color: rgba(0, 0, 0, 0.2);
}

.document-table {
  flex: 1;
  overflow-y: auto;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.document-table table {
  width: 100%;
  border-collapse: collapse;
}

.document-table body {
  overflow: scroll;
}

.document-table th,
.document-table td {
  padding: 5px 10px;
  text-align: left;

  border: none;
  text-align: left;
  height: 30px;
}
.document-table th {
  background: #003a75;
  color: #ffffff;
  font-size: 16px;
}
.document-table td {
  color: black;
  font-size: 12px;
  position: relative;
}

.document-table tr:nth-child(odd) {
  background-color: #f6f6f6;
}
.document-table tr:nth-child(enem) {
  background-color: white;
}
.document-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pagination-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-div > span {
  color: #003a75;
  font-size: 14px;
  margin-right: 10px;
}
.pagination-buttons {
  border-radius: 4px;
  cursor: pointer;
  background: white;
  color: #003a75;
  transition: background 0.3s;
  border: 1px solid #003a75;
  padding: 5px;
  margin-left: 0;
  margin-right: 10px;

  border-radius: 3px;
  color: white;
  line-height: 1;
  cursor: pointer;

  font-size: 12px;
  font-family: arial;
  height: 30px;
}

.pagination-buttons-save {
  color: #ffb200;
  border: 1px solid #ffb200;
}

.document-footer button:hover {
  background: #0056b3;
}

.image-container {
  position: relative;
  display: inline-block;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.main-image {
  display: block;
  margin-bottom: "20px";
  border: 1px solid blue;
  overflow: auto;
}

.box {
  position: absolute;
  box-sizing: border-box;
}

.accordion-title {
  height: 10px;
  background-color: #003a75;
  padding: 10px 20px;

  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
}
.accordion-item {
  width: 100%;
}
.accordion-title > span {
  font-weight: 600;
  font-size: 16px;
}
.fa-angle-double-down::before,
.fa-angle-double-right::before {
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
.thumbnail-image {
  float: left;
  display: block;
  filter: opacity(0.6);
}
.thumbnail-pdf {
  height: 100%;
  width: 100%;
  float: left;
  filter: opacity(0.6);
}

.pdf-doc {
  height: 100%;
  width: 100%;
}
.thumbnail-doc-type {
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 20px;
}
.thumbnail-image-container {
  width: 100%;
  padding-right: 20px;
  display: flex;

  overflow: scroll;

  scrollbar-width: 0px;
  scroll-behavior: none;
}
.thumbnail > span {
  color: white;
  background-color: #4caf50;
  border-radius: 3px;
  padding: 2px;
}

.fa-arrow-right::before,
.fa-arrow-left::before,
.fa-filter::before,
.fa-pencil::before,
.fa-backward::before,
.fa-file-excel::before {
  font-size: 14px;
  color: #003a75;
  cursor: pointer;
}

.document-viewer-row .fa-floppy-disk::before {
  color: #ffb200;
  cursor: pointer;
}
.document-viewer-row .fa-plus-square {
  cursor: pointer;
  margin-right: 10px;
}

.document-viewer-row .fa-trash::before {
  color: #b30037;
  cursor: pointer;
  position: absolute;
  transform: translateX(-20px);
}

.document-viewer-row .fa-angles-down::before,
.document-viewer-row .fa-angles-right::before {
  cursor: pointer;
  padding-right: 10px;
  font-size: 14px;
}
.document-viewer-row .fa-table::before,
.table-modal-body .fa-table::before,
.table-modal-body .fa-list::before {
  cursor: pointer;
  padding-right: 10px;
  font-size: 14px;
  color: #003a75;
}

.next {
  background-color: #ffb200;
}
.action-buttons {
  font-weight: 700;
}
.header-1,
.header-2 {
  width: 33%;
}
.header-3 {
  width: auto;
}

.table-modal-body {
  display: flex;
  flex-direction: column;
}
.table-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.table-modal-header button {
  cursor: pointer;
  background: #003a75;
  color: white;
  margin-left: 10px;
  font-size: 10px;
  font-family: arial;
  min-height: 20px;
  height: 25px;
  line-height: 1;
  border-radius: 4px;
  padding: 0 8px;
  outline: 0;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.modal-logo-title {
  display: flex;
  align-items: center;
}
.modal-action-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.modal-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icon-title-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
