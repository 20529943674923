.stepper {
  display: block;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 65px;
  background: white;
  border-bottom: 1px solid #bababc;
  box-sizing: border-box;
  min-width: 1200px;
  box-shadow: 0 9px 8px -7px rgba(0, 0, 0, 0.4) inset !important;
  overflow: hidden;
}

.active-stepper::before {
  content: "";
  display: block;
  width: 25px;
  height: 50px;
  background: #ffb200 !important;
  position: absolute;
  left: -25px;
  top: 0;
  z-index: 1;
}

.active-stepper,
.inactive-stepper {
  display: inline-block;
  line-height: 50px;
  padding-left: 15px;
  height: 50px;
  float: left;

  font-size: 14px;
}
.inactive-stepper {
  color: #ffb200;
}

.stepper-title {
  position: relative;
  display: inline-block;
  height: 50px;
  float: left;
}

.arrow {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 50px;
  overflow: hidden;
  margin-left: 20px;
}

.arrow::before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 25px solid white;
  border-bottom: 25px solid white;
  border-left: 25px solid #808080;
  box-sizing: border-box;
  z-index: 1;
}
.active-stepper .arrow::after {
  border-left-color: #ffb200;
}
.arrow::after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 50px;
  position: absolute;
  top: 0;
  left: -1px;
  border-left: 25px solid white;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  box-sizing: border-box;
  z-index: 2;
}
.line {
  width: 25px;
  z-index: 16 !important;
  box-shadow: 0 9px 8px -7px rgba(0, 0, 0, 0.4) inset;
  display: inline-block;
  height: 50px;
  float: left;
}
.inactive-stepper .line {
  position: relative !important;
  right: -20px;
  margin-right: -25px;
}

.active-stepper .line {
  position: absolute !important;
  right: 0;
  margin-right: 0 !important;
}

.active-stepper {
  background: #ffb200;
  color: white;
  position: relative;
  box-shadow: 0 9px 8px -7px rgba(0, 0, 0, 0.4) inset !important;
}
