.header-table {
  border-collapse: collapse;
  width: 100%;
}

.header-table th,
.header-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.header-table th,
.header-table tr {
  background-color: #f2f2f2;
}

.header-table .input-with-delete {
  position: relative;
  display: flex;
  align-items: center;
  width: 100px;
}

.header-table .input-with-delete input {
  width: calc(100%);
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.header-table .input-with-delete .delete-icon {
  position: absolute;
  right: 6px;
  cursor: pointer;
}

.header-table select {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f2f2f2;
}
.header-table .fa-trash::before {
  color: #b30037;
  cursor: pointer;

  transform: translateX(-20px);
}
